import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Gallery from '../components/Gallery';
import galleryImg from '../images/gallery.jpg';

const GalleryPage = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;

  return (
    <Layout pageTitle={attributes.gallery} copyright={attributes.shibuya.copyright}>
      <Hero backgroundImage={galleryImg} attributes={attributes} />
      <Gallery attributes={attributes} />
    </Layout>
  );
};

export default GalleryPage;

GalleryPage.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        gallery: PropTypes.string,
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        copyright: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
    title
    shibuya {
      gallery
      titleHome
      titleAboutUs
      titleMenu
      titleReserve
      titleFaq
      titleContact
      copyright
    }
  }
}
`;
